import { Link } from "react-router-dom"
import { HashLink } from "react-router-hash-link"

export function Navbar() {
    return(
        <div className="navbar section">
            <div className="content">
                <Link to="/" className="navbar_logo"><img src="/images/navbar/logo.png" alt="Logo Agatha"/></Link>
                <div className="navbar_links">
                    <HashLink to="/#concepto" className="navbar_links__el">CONCEPTO</HashLink>
                    <Link to="/menu" className="navbar_links__el">MENÚ</Link>
                    <a href="tel:6692286710" className="navbar_links__el">RESERVAR</a>
                </div>
            </div>
        </div>
    )
}