export const entradas = [
    { 
        'id':'left',
        'platillos': [
            {
                'nombre':'TOSTADA DE JAIBA',
                'gramos':'(80 gr)',
                'des':'Mayo habanero, cebolla morada, zanahoria frita y aguacate.',
                'precio':'175'
            },
            {
                'nombre':'WON TON DE SALMÓN',
                'gramos':'(80 gr)',
                'des':'Tostada oriental, nuez de la India, cebollín, algas, mayo spicy, miel de soya y aguacate.',
                'precio':'175'
            },
            {
                'nombre':'TOSTADA SPICY DE ATÚN',
                'gramos':'(80 gr)',
                'des':'Con pepino, aguacate, apio y cebolla.',
                'precio':'165'
            },
            {
                'nombre':'BETABEL CON QUESO DE CABRA Y PISTACHE.',
                'gramos':'',
                'des':'',
                'precio':'205'
            },
            {
                'nombre':'ENSALADA TRUFADA',
                'gramos':'',
                'des':'Mix de lechugas con aderezo de parmesano y aceite de trufa blanca, parmesano, tomates cherry, con camarones cocidos y aliñados con aceite y limón eureka.',
                'precio':'295'
            },
            {
                'nombre':'AGUACHILE DE NEW YORK',
                'gramos':'(200 gr)',
                'des':'Marinado en salsas negras, aguacate y cilantro.',
                'precio':'335'
            },
            {
                'nombre':'AGUACHILE',
                'gramos':'(150 gr)',
                'des':'Tradicional de camarón',
                'precio':'225'
            },
            {
                'nombre':'CLAM CHOWDER',
                'gramos':'',
                'des':'Crema de almeja. En hogaza de pan + 25',
                'precio':'175'
            },
            {
                'nombre':'COLIFLOR ZARANDEADA',
                'gramos':'',
                'des':'Con aderezo tradicional, asada a la parrilla.',
                'precio':'175'
            },
            {
                'nombre':'CRABCAKES',
                'gramos':'(150 gr)',
                'des':'Croquetas de jaiba con mayonesa habanero y ensalada verde.',
                'precio':'175'
            },
        ]
    },
    {
        'id':'right',
        'platillos': [
            {
                'nombre':'TACOS GOBERNADOR',
                'gramos':'(2 pz)',
                'des':'A la parrilla con queso, camarón y morrón.',
                'precio':'140'
            },
            {
                'nombre':'TACOS DE PULPO',
                'gramos':'(2 pz)',
                'des':'Con costra de queso, aguacate y salsa de chile chiltepín.',
                'precio':'145'
            },
            {
                'nombre':'TACOS DE LENGUA',
                'gramos':'(2 pz)',
                'des':'',
                'precio':'220'
            },
            {
                'nombre':'ROLLOS PRIMAVERA DE PATO',
                'gramos':'(5 pz)',
                'des':'Con salsa de cacahuate.',
                'precio':'280'
            },
            {
                'nombre':'TUÉTANOS CON HONGOS',
                'gramos':'(4 pz)',
                'des':'',
                'precio':'285'
            },
        ]
    }
]

/* Entradas */
export const entradas_frias = [
    { 
        'id':'left',
        'platillos': [
            {
                'nombre':'TOSTADA DE JAIBA',
                'gramos':'(80 gr)',
                'des':'Mayo habanero, cebolla morada, zanahoria frita y aguacate.',
                'precio':'175'
            },
            {
                'nombre':'WON TON DE SALMÓN',
                'gramos':'(80 gr)',
                'des':'Tostada oriental, Nuez de la India, cebollín, algas, mayo spicy, miel de soya y aguacate.',
                'precio':'175'
            },
            {
                'nombre':'TOSTADA SPICY DE ATÚN',
                'gramos':'(80 gr)',
                'des':'Con pepino, aguacate, apio y cebolla.',
                'precio':'165'
            },
            {
                'nombre':'BETABEL CON QUESO DE CABRA Y PISTACHE',
                'gramos':'',
                'des':'',
                'precio':'205'
            },
            {
                'nombre':'AGUACHILE DE NEW YORK',
                'gramos':'(200 gr)',
                'des':'Marinado en salsas negras, aguacate y cilantro.',
                'precio':'335'
            },
            {
                'nombre':'CARPACCIO DE ZUCCINI',
                'gramos':'',
                'des':'Láminas de zuccini a la parrilla con fruta de temporada, queso feta, almendra tostada y vinagreta bálsamica y miel de abeja.',
                'precio':'215'
            },
            {
                'nombre':'ENSALADA TRUFADA',
                'gramos':'',
                'des':'Mix de lechugas con aderezo de parmesano y aceite de trufa blanca, parmesano, tomates cherry, camarones cocidos aliñados con aceite y limón eureka.',
                'precio':'295'
            },
        ]
    },
    {
        'id':'right',
        'platillos': [
            {
                'nombre':'AGUACHILE',
                'gramos':'(150 gr)',
                'des':'Tradicional de camarón.',
                'precio':'225'
            },
            {
                'nombre':'TIRADITO DE HAMACHI',
                'gramos':'(100 gr)',
                'des':'Con salsa ponzu, masago y serrano.',
                'precio':'345'
            },
            {
                'nombre':'CEVICHE DE HAMACHI',
                'gramos':'(100 gr)',
                'des':'Vinagreta de Yusukosho y limón eureka tatemado, alga wakame, pepino, cebollin y cilantro.',
                'precio':'345'
            }
        ]
    }
]

/* Entradas calientes */
export const entradas_calientes = [
    { 
        'id':'left',
        'platillos': [
            {
                'nombre':'CLAM CHOWDER',
                'gramos':'',
                'des':'Crema de almeja. En hogaza de pan + 25',
                'precio':'175'
            },
            {
                'nombre':'COLIFLOR ZARANDEADA',
                'gramos':'',
                'des':'Con aderezo tradicional, asada a la parrilla.',
                'precio':'175'
            },
            {
                'nombre':'CRABCAKES',
                'gramos':'(150 gr)',
                'des':'Croquetas de jaiba con mayonesa habanero y ensalada verde.',
                'precio':'175'
            },
            {
                'nombre':'PULPO AL AJILLO',
                'gramos':'(150gr)',
                'des':'Con papas cambray salteadas con pimentón.',
                'precio':'335'
            },
            {
                'nombre':'TACOS GOBERNADOR',
                'gramos':'(2 pzs.)',
                'des':'A la parrilla con queso, camarón y morrón.',
                'precio':'140'
            },
            {
                'nombre':'TACOS ENCOSTRADOS DE PULPO',
                'gramos':'(2 pzs)',
                'des':'Con costra de queso, aguacate y salsa de chile chiltepín.',
                'precio':'145'
            },
            {
                'nombre':'MEJILLONES EN QUESO AZUL',
                'gramos':'',
                'des':'Con panko tostado en mantequilla avellanada, perejil y salsa macha.',
                'precio':'285'
            },
            {
                'nombre':'QUESO BRIE HORNEADO',
                'gramos':'',
                'des':'Con compota de manzana verde y albahaca, pan de maza madre tostado y ensalada de uva con arúgula.',
                'precio':'395'
            },
        ]
    },
    {
        'id':'right',
        'platillos': [
            /*
            {
                'nombre':'GRILLED CHEESE DE 265 SHORT RIB',
                'gramos':'(150 gr)',
                'des':'Con papas gajo y dip de cebolla.',
                'precio':'265'
            },
            */
            {
                'nombre':'TACOS DE LECHÓN',
                'gramos':'(4 pz.)',
                'des':'Tortilla de harina, salsa morita y guacamole.',
                'precio':'295'
            },
            {
                'nombre':'ROLLOS PRIMAVERA DE PATO CONFITADO',
                'gramos':'(5 pz)',
                'des':'Con salsa de cacahuate',
                'precio':'280'
            },
            {
                'nombre':'TUETANOS CON HONGOS',
                'gramos':'(4 pzas)',
                'des':'Con pan de masa madre .',
                'precio':'285'
            },
            {
                'nombre':'OSTIONES AGATHA',
                'gramos':'',
                'des':'2 pz Rockefeller, 2 pz a la parrilla y 2 pz frescos, con vinagreta de jengibre',
                'precio':'260'
            },
        ]
    }
]

/* Platos fuertes */
export const platos_fuertes = [
    { 
        'id':'left',
        'platillos': [
            {
                'nombre':'BALLOTINE DE POLLO',
                'gramos':'(220 gr)',
                'des':'Pechuga de pollo rellena de quesos y espinaca puré ahumado de coliflor, calabacita con almendras y jus de ave.',
                'precio':'280'
            },
            {
                'nombre':'SALMÓN CANADIENSE',
                'gramos':'(225 gr)',
                'des':'Mantequilla de limón y tomillo y arroz basmati con mezcla de hongos.',
                'precio':'395'
            },
            {
                'nombre':'PORK BELLY',
                'gramos':'(200 gr)',
                'des':'Puré de plátano macho rostizado, portobellos, jus de carne.',
                'precio':'295'
            },
            {
                'nombre':'CHAMORRO DE CERDO',
                'gramos':'(700 gr)',
                'des':'Con papas Pont Neuf.',
                'precio':'310'
            },
            {
                'nombre':'HAMBURGUESA ANGUS',
                'gramos':'(180 gr)',
                'des':'Pan brioche hecho en casa, queso cheddar, aderezo y papas a la francesa.',
                'precio':'295'
            },
            {
                'nombre':'COSTILLAS DE CERDO',
                'gramos':'(400 gr)',
                'des':'Con salsa BBQ Jack Daniels y miel, con papas a la francesa y elote al grill.',
                'precio':'360'
            },
            {
                'nombre':'RACK DE CORDERO',
                'gramos':'(Aprox. 300 gr)',
                'des':'De Nueva Zelanda, con salsa de menta, verduras al grill y puré de papa cremoso.',
                'precio':'715'
            },
            {
                'nombre':'PESCA AL MOJO DE AJO Y PEREJIL',
                'gramos':'(220 gr)',
                'des':'Con ensalada de arúgula, tomate cherry y juliana de cebolla.',
                'precio':'320'
            },
        ]
    },
    {
        'id':'right',
        'platillos': [
            {
                'nombre':'FILETE DE RES',
                'gramos':'(225 gr)',
                'des':'Salsa de hongos y tocino, puré de papa y vegetales glaseados',
                'precio':'410'
            },
            {
                'nombre':'CAMARÓNES GIGANTES',
                'gramos':'(5 pz)',
                'des':'Mantequilla de jengibre y miso con ensalada de pepino y pera',
                'precio':'490'
            },
            {
                'nombre':'SHORT RIB',
                'gramos':'(800 gr para compartir)',
                'des':'Encacahuatado, tortillas y papas en salsa de yogurt',
                'precio':'940'
            },
            {
                'nombre':'PULPO A LAS BRASAS',
                'gramos':'(300 gr)',
                'des':'Pimientos asados en mojo de cilantro',
                'precio':'650'
            },
        ]
    }
]

/* De la parrilla */
export const parrilla = [
    { 
        'id':'left',
        'platillos': [
            {
                'nombre':'PULPO',
                'gramos':'(300 gr)',
                'des':'Zarandeado',
                'precio':'650'
            },
            {
                'nombre':'CAMARONES A LA PARRILLA',
                'gramos':'(300 gr)',
                'des':'',
                'precio':'490'
            },
            {
                'nombre':'PICAÑA',
                'gramos':'(500 gr)',
                'des':'HIGH CHOICE',
                'precio':'625'
            },
            {
                'nombre':'PICAÑA',
                'gramos':'(300 gr)',
                'des':'HIGH CHOICE',
                'precio':'470'
            },
            {
                'nombre':'FILETE DE RES',
                'gramos':'(250 gr)',
                'des':'NAC',
                'precio':'572'
            },
            {
                'nombre':'COWBOY RES',
                'gramos':'(1-1.100 gr.)',
                'des':'',
                'precio':'1,300'
            }
        ]
    },
    {
        'id':'right',
        'platillos': [
            {
                'nombre':'NEW YORK',
                'gramos':'(400 gr)',
                'des':'HIGH CHOICE',
                'precio':'635'
            },
            {
                'nombre':'NEW YORK',
                'gramos':'(300 gr)',
                'des':'HIGH CHOICE',
                'precio':'490'
            },
            {
                'nombre':'VACÍO',
                'gramos':'(300 gr)',
                'des':'HIGH CHOICE',
                'precio':'440'
            },
            {
                'nombre':'RIBEYE',
                'gramos':'(450 gr)',
                'des':'HIGH CHOICE',
                'precio':'895'
            },
            {
                'nombre':'RIBEYE',
                'gramos':'(300 gr)',
                'des':'HIGH CHOICE',
                'precio':'575'
            },
            {
                'nombre':'PORTER HOUSE',
                'gramos':'(1.100 gr.)',
                'des':'HIGH CHOICE',
                'precio':'1,620'
            }
        ]
    }
]

/* De la parrilla */
export const cortes_americanos = [
    { 
        'id':'left',
        'platillos': [
            {
                'nombre':'NEW YORK',
                'gramos':'(400 gr)',
                'des':'',
                'precio':'635'
            },
            {
                'nombre':'NEW YORK',
                'gramos':'(500 gr)',
                'des':'',
                'precio':'750'
            },
            {
                'nombre':'RIB EYE',
                'gramos':'(400 gr)',
                'des':'',
                'precio':'720'
            },
            {
                'nombre':'RIB EYE',
                'gramos':'(500 gr)',
                'des':'',
                'precio':'850'
            },
            {
                'nombre':'PICAÑA',
                'gramos':'(300 gr)',
                'des':'',
                'precio':'470'
            },
            {
                'nombre':'PICAÑA',
                'gramos':'(500 gr)',
                'des':'',
                'precio':'625'
            },
        ]
    },
    {
        'id':'right',
        'platillos': [
            {
                'nombre':'VACÍO',
                'gramos':'(300 gr)',
                'des':'',
                'precio':'440'
            },
            {
                'nombre':'COWBOY RES',
                'gramos':'(1-1.100 gr)',
                'des':'',
                'precio':'2,150'
            },
            {
                'nombre':'PORTER HOUSE',
                'gramos':'(1-1.100 gr)',
                'des':'',
                'precio':'1,850'
            },
        ]
    }
]

/* De la parrilla */
export const cortes_argentinos = [
    { 
        'id':'left',
        'platillos': [
            {
                'nombre':'BIFE ANCHO',
                'gramos':'(400 gr)',
                'des':'',
                'precio':'750'
            },
            {
                'nombre':'BIFE ANCHO',
                'gramos':'(500 gr)',
                'des':'',
                'precio':'880'
            },
            {
                'nombre':'BIFE ANGOSTO',
                'gramos':'(400 gr)',
                'des':'',
                'precio':'720'
            },
            {
                'nombre':'BIFE ANGOSTO',
                'gramos':'(500 gr)',
                'des':'',
                'precio':'850'
            },
        ]
    },
    {
        'id':'right',
        'platillos': [
            {
                'nombre':'',
                'gramos':'(400 gr)',
                'des':'',
                'precio':''
            },
            {
                'nombre':'',
                'gramos':'(400 gr)',
                'des':'',
                'precio':''
            },
        ]
    }
]

/* Guarniciones */
export const guarniciones = [
    { 
        'id':'left',
        'platillos': [
            {
                'nombre':'PAPAS Y CAMOTES A LA FRANCESA',
                'gramos':'',
                'des':'',
                'precio':'95'
            },
            {
                'nombre':'PURE DE PAPA',
                'gramos':'',
                'des':'',
                'precio':'95'
            },
            {
                'nombre':'PAPA AL HORNO',
                'gramos':'',
                'des':'',
                'precio':'95'
            },
            {
                'nombre':'PAPAS CAMBRAY “A LA DIABLA”',
                'gramos':'',
                'des':'',
                'precio':'95'
            },
            {
                'nombre':'PAPAS A LA FRANCESA TRUFADAS',
                'gramos':'',
                'des':'',
                'precio':'130'
            },
        ]
    },
    {
        'id':'right',
        'platillos': [
            {
                'nombre':'GUACAMOLE',
                'gramos':'',
                'des':'',
                'precio':'95'
            },
            {
                'nombre':'ESPARRAGOS',
                'gramos':'',
                'des':'',
                'precio':'160'
            },
            {
                'nombre':'ENSALADA VERDE',
                'gramos':'',
                'des':'',
                'precio':'95'
            },
            {
                'nombre':'VEGETALES AL GRILL',
                'gramos':'',
                'des':'',
                'precio':'95'
            },
        ]
    }
]

/* Postres */
export const postres = [
    { 
        'id':'left',
        'platillos': [
            {
                'nombre':'CHEESECAKE DE ELOTE',
                'gramos':'',
                'des':'Con nieve de vainilla de Papantla y salsa de cajeta.',
                'precio':'175'
            },
            {
                'nombre':'CHEESECAKE Y FRUTOS ROJOS',
                'gramos':'',
                'des':'Pastel de queso estilo New York con nieve de frutos rojos.',
                'precio':'175'
            },
            {
                'nombre':'MOLTEN DE CHOCOLATE (Horneado al momento/20 minutos aprox)',
                'gramos':'',
                'des':'Volcán de chocolate y especias con nieve de vainilla de Papantla.',
                'precio':'160'
            },
            {
                'nombre':'MOLTEN DE DULCE DE LECHE (Horneado al momento/20 minutos aprox)',
                'gramos':'',
                'des':'Volcán de dulce de leche con nieve de vainilla de Papantla.',
                'precio':'160'
            },
            {
                'nombre':'CREME BRULEE',
                'gramos':'',
                'des':'Tradicional caramelizado, acompañado de frutos rojos.',
                'precio':'130'
            }
        ]
    },
    {
        'id':'right',
        'platillos': [
            {
                'nombre':'BROWNIE',
                'gramos':'',
                'des':'Con nieve de avellana.',
                'precio':'155'
            },
            {
                'nombre':'PAY HELADO DE DULCE DE LECHE',
                'gramos':'',
                'des':'Con crumble de chocolate blanco, bañado con dulce de leche y almendras tostadas.',
                'precio':'165'
            }
        ]
    }
]

/* Digestivos */
export const digestivos = [
    { 
        'id':'left',
        'platillos': [
            {
                'nombre':'CARAJILLO',
                'gramos':'110 ml',
                'des':'Espresso, Licor 43 y Licor de Café',
                'precio':'185'
            },
            {
                'nombre':'CARAJILLO BAILEYS',
                'gramos':'110 ml',
                'des':'Espresso y Baileys',
                'precio':'185'
            },
        ]
    },
    {
        'id':'right',
        'platillos': [
            {
                'nombre':'ZACARILLO',
                'gramos':'110 ml',
                'des':'Espresso, Ron Zacapa y Licor de Avellana',
                'precio':'185'
            }
        ]
    }
]

/* Rollos */
export const rollos = [
    { 
        'id':'left',
        'platillos': [
            {
                'nombre':'HAMACHI ROLL',
                'gramos':'',
                'des':'Relleno de atún, pepino, queso crema, aguacate, forrado de Hamachi con mayonesa de masago.',
                'precio':'335'
            },
            {
                'nombre':'FAUSTO ROLL',
                'gramos':'',
                'des':'Relleno de camarón empanizado, pepino, aguacate, capeado, con topping flameado de pulpo, atún y camarón cocido spicy, miel de soya y ajonjolí.',
                'precio':'345'
            },
            {
                'nombre':'AGATHA ROLL',
                'gramos':'',
                'des':'Relleno de camarón empanizado, cebollín, queso crema y coco. Envuelto en Kanikama y mayo habanero',
                'precio':'285'
            },
            {
                'nombre':'UNAGUI ROLL',
                'gramos':'',
                'des':'Anguila tostada por fuera, relleno de aguacate y salmón.',
                'precio':'310'
            },
            {
                'nombre':'TUNA SPICY ROLL',
                'gramos':'',
                'des':'Atún con mayonesa spicy por fuera, salmón, pepino y queso crema por dentro.',
                'precio':'285'
            },
            {
                'nombre':'SALMÓN TRUFADO',
                'gramos':'',
                'des':'Relleno de camarón cocido, pepino y aguacate con topping de salmón con alioli trufado flameado.',
                'precio':'310'
            },
        ]
    },
    {
        'id':'right',
        'platillos': [
            {
                'nombre':'MAGURO KAKIAGE',
                'gramos':'',
                'des':'Relleno de queso, esparrago y camarón cocido, con topping de láminas de atún y kakiage de verduras.',
                'precio':'295'
            },
            {
                'nombre':'AVOCADO ROLL',
                'gramos':'',
                'des':'Forrado de aguacate, relleno de pepino, queso, en hoja de soya',
                'precio':'255'
            },
        ]
    }
]

/* Sashimis */
export const sashimis = [
    { 
        'id':'left',
        'platillos': [
            {
                'nombre':'ATÚN',
                'gramos':'(100 GR)',
                'des':'',
                'precio':'190'
            },
            {
                'nombre':'SALMÓN',
                'gramos':'(100 GR)',
                'des':'',
                'precio':'215'
            },
        ]
    },
    {
        'id':'right',
        'platillos': [
            {
                'nombre':'PULPO',
                'gramos':'(100 GR)',
                'des':'',
                'precio':'225'
            },
            {
                'nombre':'HAMACHI',
                'gramos':'(100 GR)',
                'des':'',
                'precio':'345'
            },
        ]
    }
]

/* Temaki */
export const temaki = [
    { 
        'id':'left',
        'platillos': [
            {
                'nombre':'ATÚN',
                'gramos':'',
                'des':'',
                'precio':'145'
            },
            {
                'nombre':'SALMÓN',
                'gramos':'',
                'des':'',
                'precio':'185'
            },
        ]
    },
    {
        'id':'right',
        'platillos': [
            {
                'nombre':'HAMACHI',
                'gramos':'',
                'des':'',
                'precio':'225'
            },
        ]
    }
]

/* Nigiris */
export const nigiris = [
    { 
        'id':'left',
        'platillos': [
            {
                'nombre':'ATÚN',
                'gramos':'',
                'des':'Ahí Tuna / Maguro',
                'precio':'60'
            },
            {
                'nombre':'SALMÓN',
                'gramos':'',
                'des':'Salmón / Sake',
                'precio':'75'
            },
            {
                'nombre':'HAMACHI',
                'gramos':'',
                'des':'Cola amarilla  /  Yellowtail Amberjak',
                'precio':'130'
            },
            {
                'nombre':'PESCA DEL DIA',
                'gramos':'',
                'des':'White fish / Shiromoi',
                'precio':'60'
            },
        ]
    },
    {
        'id':'right',
        'platillos': [
            {
                'nombre':'CAMARÓN',
                'gramos':'',
                'des':'Prawn / Ebi',
                'precio':'60'
            },
            {
                'nombre':'ANGUILA',
                'gramos':'',
                'des':'Fresh wáter eel / Unagi',
                'precio':'125'
            },
            {
                'nombre':'HUEVA DE CAPELAN',
                'gramos':'',
                'des':'Capelin roe / Masago',
                'precio':'75'
            },
            {
                'nombre':'HUEVA DE SALMÓN',
                'gramos':'',
                'des':'Salmón roe / Ikura',
                'precio':'115'
            },
        ]
    }
]

export const vinos = [
    {
        'nombre':'CHAMPAGNE',
        'botella':'',
        'copa':'',
        'elements': [
            {
                'nombre':'DOM PERIGNON',
                'subtitulo':'',
                'botella':'12,950',
                'copa':''
            },
            {
                'nombre':'MOET & CHANDON IMPERIAL',
                'subtitulo':'',
                'botella':'3,110',
                'copa':''
            },
            {
                'nombre':'MOET & CHANDON NECTAR IMPERIAL',
                'subtitulo':'',
                'botella':'3,210',
                'copa':''
            },
        ]
    },
    {
        'nombre':'VINOS ESPUMOSOS',
        'botella':'',
        'copa':'',
        'elements': [
            {
                'nombre':'FOGOSO GOLD',
                'subtitulo':'',
                'botella':'1,040',
                'copa':''
            },
        ]
    },
    {
        'nombre':'VINOS BLANCOS',
        'botella':'',
        'copa':'',
        'elements': [
            {
                'nombre':'INDIGO EYES CHARDONNAY',
                'subtitulo':'',
                'botella':'870',
                'copa':''
            },
            {
                'nombre':'LIBERTAS CHARDONNAY',
                'subtitulo':'',
                'botella':'490',
                'copa':'150'
            },
            {
                'nombre':'LIBERTAS SAUVIGNON BLANC',
                'subtitulo':'',
                'botella':'490',
                'copa':'150'
            },
            {
                'nombre':'BOSIO MOSCATO',
                'subtitulo':'',
                'botella':'1,030',
                'copa':''
            },
        ]
    },
    {
        'nombre':'VINOS ROSADOS',
        'botella':'',
        'copa':'',
        'elements': [
            {
                'nombre':'LIBERTAS ROSÉ',
                'subtitulo':'',
                'botella':'490',
                'copa':'150'
            },
            {
                'nombre':'BERINGER WHITE ZINFANDEL',
                'subtitulo':'',
                'botella':'845',
                'copa':''
            },
            {
                'nombre':'CASA MADERO V',
                'subtitulo':'',
                'botella':'1,040',
                'copa':''
            },
        ]
    },
    {
        'nombre':'VINOS TINTOS',
        'botella':'',
        'copa':'',
        'elements': [
            {
                'nombre':'VIN QUINN',
                'subtitulo':'',
                'botella':'650',
                'copa':'170'
            },
            {
                'nombre':'INDIGO EYES CABERNET',
                'subtitulo':'',
                'botella':'870',
                'copa':'220'
            },
            {
                'nombre':'LIBERTAS MERLOT',
                'subtitulo':'',
                'botella':'490',
                'copa':'150'
            },
            {
                'nombre':'EL MOLINO MALBEC',
                'subtitulo':'',
                'botella':'705',
                'copa':'180'
            },
            {
                'nombre':'CENTUM, SYRAH, GARNACHA',
                'subtitulo':'',
                'botella':'845',
                'copa':''
            },
            {
                'nombre':'ALTANAVA TEMPRANILLO',
                'subtitulo':'',
                'botella':'1,240',
                'copa':''
            },
            {
                'nombre':'INEDITO ROBLE',
                'subtitulo':'Malbec, primitivo, tempranillo',
                'botella':'1,600',
                'copa':''
            },
            {
                'nombre':'CAVALL 3 SHIRAZ',
                'subtitulo':'',
                'botella':'1,055',
                'copa':''
            },
            {
                'nombre':'INDIGO EYES PINOT NOIR',
                'subtitulo':'',
                'botella':'870',
                'copa':''
            },
            {
                'nombre':'MAGONI ORIGEN 43 SANGIOVESE',
                'subtitulo':'',
                'botella':'1,210',
                'copa':''
            },
            {
                'nombre':'MAGONI NEBBIOLO',
                'subtitulo':'',
                'botella':'1,460',
                'copa':''
            },
            {
                'nombre':'CASA MADERO SHIRAZ',
                'subtitulo':'',
                'botella':'1,395',
                'copa':''
            },
            {
                'nombre':'VINO DE PIEDRA',
                'subtitulo':'',
                'botella':'2,645',
                'copa':''
            },
            {
                'nombre':'LA CETTO NEBBIOLO',
                'subtitulo':'',
                'botella':'810',
                'copa':''
            },
            {
                'nombre':'SURCO 2.7',
                'subtitulo':'',
                'botella':'1,100',
                'copa':''
            },
            {
                'nombre':'SURCO ROJO',
                'subtitulo':'',
                'botella':'1,455',
                'copa':''
            },
            {
                'nombre':'CASA MADERO 3 V',
                'subtitulo':'',
                'botella':'1,350',
                'copa':''
            },
        ]
    },
]

/* Destiladoss */
export const destilados = [
    {
        'nombre':'RON',
        'botella':'',
        'copa':'2oz / 60ml',
        'elements': [
            {
                'nombre':'MALIBÚ',
                'subtitulo':'750 ML',
                'botella':'1,100',
                'copa':'110'
            },
            {
                'nombre':'APPLETON STATE SIGNATURE',
                'subtitulo':'750 ML',
                'botella':'1,250',
                'copa':'115'
            },
            {
                'nombre':'APPLETON STATE 12 AÑOS',
                'subtitulo':'750 ML',
                'botella':'2,440',
                'copa':'225'
            },
            {
                'nombre':'BACARDI 8',
                'subtitulo':'700 ML',
                'botella':'2,100',
                'copa':'215'
            },
            {
                'nombre':'BACARDI BLANCO',
                'subtitulo':'750 ML',
                'botella':'1,140',
                'copa':'95'
            },
            {
                'nombre':'BOTRAN AÑEJO 8',
                'subtitulo':'750 ML',
                'botella':'2,265',
                'copa':'210'
            },
            {
                'nombre':'CAPITAN MORGAN',
                'subtitulo':'750 ML',
                'botella':'950',
                'copa':'95'
            },
            {
                'nombre':'FLOR DE CAÑA 4 DRY',
                'subtitulo':'750 ML',
                'botella':'980',
                'copa':'98'
            },
            {
                'nombre':'HAVANA CLUB 7 AÑEJO',
                'subtitulo':'700 ML',
                'botella':'1,800',
                'copa':'170'
            },
            {
                'nombre':'MATUZALEM CLASICO',
                'subtitulo':'750 ML',
                'botella':'1,280',
                'copa':'110'
            },
            {
                'nombre':'ZACAPA 23',
                'subtitulo':'750 ML',
                'botella':'3,600',
                'copa':'310'
            },
        ]
    },
    {
        'nombre':'TEQUILA',
        'botella':'',
        'copa':'2oz / 60ml',
        'elements': [
            {
                'nombre':'7 LEGUAS BLANCO',
                'subtitulo':'750 ML',
                'botella':'2,440',
                'copa':'210'
            },
            {
                'nombre':'7 LEGUAS REPOSADO',
                'subtitulo':'750 ML',
                'botella':'2,730',
                'copa':'250'
            },
            {
                'nombre':'CENTENARIO PLATA',
                'subtitulo':'700 ML',
                'botella':'1,860',
                'copa':'175'
            },
            {
                'nombre':'DOBEL DIAMANTE',
                'subtitulo':'750 ML',
                'botella':'2,900',
                'copa':'225'
            },
            {
                'nombre':'DON JULIO 70',
                'subtitulo':'750 ML',
                'botella':'3,715',
                'copa':'320'
            },
            {
                'nombre':'DON JULIO BLANCO',
                'subtitulo':'750 ML',
                'botella':'2,380',
                'copa':'205'
            },
            {
                'nombre':'DON JULIO REPOSADO',
                'subtitulo':'750 ML',
                'botella':'2,845',
                'copa':'245'
            },
            {
                'nombre':'HERRADURA ANTIGUO PLATA',
                'subtitulo':'700 ML',
                'botella':'1,390',
                'copa':'130'
            },
            {
                'nombre':'HERRADURA BLANCO',
                'subtitulo':'700 ML',
                'botella':'3,020',
                'copa':'195'
            },
            {
                'nombre':'HERRADURA REPOSADO',
                'subtitulo':'700 ML',
                'botella':'2,900',
                'copa':'280'
            },
            {
                'nombre':'HERRADURA ULTRA',
                'subtitulo':'700 ML',
                'botella':'2,620',
                'copa':'255'
            },
        ]
    },
    {
        'nombre':'VODKA',
        'botella':'',
        'copa':'2oz / 60ml',
        'elements': [
            {
                'nombre':'BELVEDERE',
                'subtitulo':'750 ML',
                'botella':'2,960',
                'copa':'255'
            },
            {
                'nombre':'CIROC',
                'subtitulo':'750 ML',
                'botella':'2,495',
                'copa':'230'
            },
            {
                'nombre':'GREY GOOSE',
                'subtitulo':'750 ML',
                'botella':'2,845',
                'copa':'255'
            },
            {
                'nombre':'KETEL ONE',
                'subtitulo':'750 ML',
                'botella':'1,915',
                'copa':'175'
            },
            {
                'nombre':'STLOSHNAYA',
                'subtitulo':'750 ML',
                'botella':'1,280',
                'copa':'120'
            },
            {
                'nombre':'ABSOLUT',
                'subtitulo':'750 ML',
                'botella':'1,220',
                'copa':'110'
            },
            {
                'nombre':'SMIRNOF',
                'subtitulo':'750 ML',
                'botella':'990',
                'copa':'100'
            },
        ]
    },
    {
        'nombre':'BOURBON',
        'botella':'',
        'copa':'2oz / 60ml',
        'elements': [
            {
                'nombre':'BULIET BOURBON',
                'subtitulo':'750 ML',
                'botella':'1,915',
                'copa':'175'
            },
            {
                'nombre':'MAKERS MARK',
                'subtitulo':'750 ML',
                'botella':'1,980',
                'copa':'185'
            },
            {
                'nombre':'WILD TURKEY RYE',
                'subtitulo':'750 ML',
                'botella':'1,750',
                'copa':'165'
            },
        ]
    },
    {
        'nombre':'IRISH WHISKEY',
        'botella':'',
        'copa':'2oz / 60ml',
        'elements': [
            {
                'nombre':'JAMESON',
                'subtitulo':'750 ML',
                'botella':'1,750',
                'copa':'165'
            },
        ]
    },
    {
        'nombre':'CANADIAN WHISKEY',
        'botella':'',
        'copa':'2oz / 60ml',
        'elements': [
            {
                'nombre':'CANADIAN CLUB',
                'subtitulo':'750 ML',
                'botella':'1,280',
                'copa':'120'
            },
            {
                'nombre':'CROWN ROYAL',
                'subtitulo':'750 ML',
                'botella':'2,100',
                'copa':'190'
            },
        ]
    },
    {
        'nombre':'SCOTCH WHISKY',
        'botella':'',
        'copa':'2oz / 60ml',
        'elements': [
            {
                'nombre':'JW BLACK LABEL',
                'subtitulo':'750 ML',
                'botella':'3,365',
                'copa':'300'
            },
            {
                'nombre':'JW DOUBLE BLACK',
                'subtitulo':'750 ML',
                'botella':'4,295',
                'copa':'380'
            },
            {
                'nombre':'JW GOLD LABEL',
                'subtitulo':'750 ML',
                'botella':'4,700',
                'copa':'440'
            },
            {
                'nombre':'JW GREEN LABEL',
                'subtitulo':'750 ML',
                'botella':'4,295',
                'copa':'380'
            },
            {
                'nombre':'JW BLUE LABEL',
                'subtitulo':'750 ML',
                'botella':'13,950',
                'copa':'1,220'
            },
            {
                'nombre':'BUCHANANS 12',
                'subtitulo':'750 ML',
                'botella':'2,550',
                'copa':'245'
            },
            {
                'nombre':'BUCHANANS 18',
                'subtitulo':'750 ML',
                'botella':'4,700',
                'copa':'440'
            },
            {
                'nombre':'CHIVAS REAGAL 18',
                'subtitulo':'750 ML',
                'botella':'4,930',
                'copa':'465'
            },
            {
                'nombre':'CHIVAS 12',
                'subtitulo':'750 ML',
                'botella':'2,380',
                'copa':'230'
            },
            {
                'nombre':'JACK DANIELS',
                'subtitulo':'700 ML',
                'botella':'1,800',
                'copa':'185'
            },
            {
                'nombre':'JACK DANIELS HONEY',
                'subtitulo':'700 ML',
                'botella':'1,625',
                'copa':'165'
            },
            {
                'nombre':'OLD PARR 12',
                'subtitulo':'750 ML',
                'botella':'2,440',
                'copa':'235'
            },
        ]
    },
    {
        'nombre':'SINGLE MALT',
        'botella':'',
        'copa':'2oz / 60ml',
        'elements': [
            {
                'nombre':'GLENFIDDICH 12',
                'subtitulo':'750 ML',
                'botella':'3,770',
                'copa':'340'
            },
            {
                'nombre':'MACALAN 12',
                'subtitulo':'700 ML',
                'botella':'4,875',
                'copa':'440'
            },
            {
                'nombre':'GLENLIVET 12',
                'subtitulo':'750 ML',
                'botella':'3,250',
                'copa':'300'
            },
        ]
    },
    {
        'nombre':'BRANDY',
        'botella':'',
        'copa':'2oz / 60ml',
        'elements': [
            {
                'nombre':'CARLOS 1',
                'subtitulo':'700 ML',
                'botella':'2,960',
                'copa':'280'
            },
            {
                'nombre':'TORRES 10',
                'subtitulo':'700 ML',
                'botella':'1,450',
                'copa':'150'
            },
        ]
    },
    {
        'nombre':'COGNAC',
        'botella':'',
        'copa':'2oz / 60ml',
        'elements': [
            {
                'nombre':'HENNESSY VS',
                'subtitulo':'700 ML',
                'botella':'3,365',
                'copa':'325'
            },
            {
                'nombre':'HENNESSY VSOP',
                'subtitulo':'700 ML',
                'botella':'4,870',
                'copa':'475'
            },
            {
                'nombre':'MARTEL VSOP',
                'subtitulo':'700 ML',
                'botella':'2,780',
                'copa':'270'
            },
        ]
    },
    {
        'nombre':'GINEBRA',
        'botella':'',
        'copa':'2oz / 60ml',
        'elements': [
            {
                'nombre':'TANQUERAY TEN',
                'subtitulo':'700 ML',
                'botella':'2,440',
                'copa':'245'
            },
            {
                'nombre':'TANQUERAY DRY',
                'subtitulo':'750 ML',
                'botella':'1,510',
                'copa':'150'
            },
            {
                'nombre':'BOMBAY SHAPPHIRE',
                'subtitulo':'750 ML',
                'botella':'1,850',
                'copa':'180'
            },
            {
                'nombre':'BULLDOG',
                'subtitulo':'750 ML',
                'botella':'2,200',
                'copa':'210'
            },
            {
                'nombre':'HENDRICKS',
                'subtitulo':'750 ML',
                'botella':'3,250',
                'copa':'290'
            },
            {
                'nombre':'MOM',
                'subtitulo':'700 ML',
                'botella':'2,150',
                'copa':'220'
            },
            {
                'nombre':'THE LONDON No. 1',
                'subtitulo':'700 ML',
                'botella':'2,900',
                'copa':'280'
            },
            {
                'nombre':'BEEFEATER',
                'subtitulo':'750 ML',
                'botella':'1,600',
                'copa':'150'
            },
        ]
    },
    {
        'nombre':'MEZCAL',
        'botella':'',
        'copa':'2oz / 60ml',
        'elements': [
            {
                'nombre':'DULCE INFIERNO CENIZO JOVEN',
                'subtitulo':'750 ML',
                'botella':'2,730',
                'copa':'280'
            },
            {
                'nombre':'AMORES JOVEN ESPADIN',
                'subtitulo':'750 ML',
                'botella':'2,380',
                'copa':'240'
            },
            {
                'nombre':'AMORES REPOSADO ESPADIN',
                'subtitulo':'750 ML',
                'botella':'2,200',
                'copa':'220'
            },
            {
                'nombre':'UNION EL VIEJO ESPADIN TOBALÁ',
                'subtitulo':'700 ML',
                'botella':'2,780',
                'copa':'290'
            },
            {
                'nombre':'UNION UNO ESPADIN, CIRIAL',
                'subtitulo':'700 ML',
                'botella':'1,950',
                'copa':'210'
            },
        ]
    },
]

/* Licores */
export const licores = [
    {
        'nombre':'no',
        'botella':'no',
        'copa':'2oz / 60ml',
        'elements': [
            {
                'nombre':'AMARETTO DISARONO',
                'subtitulo':'',
                'botella':'',
                'copa':'135'
            },
            {
                'nombre':'ANCHO REYES',
                'subtitulo':'',
                'botella':'',
                'copa':'120'
            },
            {
                'nombre':'ANCHO REYES VERDE',
                'subtitulo':'',
                'botella':'',
                'copa':'105'
            },
            {
                'nombre':'APEROL',
                'subtitulo':'',
                'botella':'',
                'copa':'110'
            },
            {
                'nombre':'BAILYES',
                'subtitulo':'',
                'botella':'',
                'copa':'120'
            },
            {
                'nombre':'CAMPARI',
                'subtitulo':'',
                'botella':'',
                'copa':'120'
            },
            {
                'nombre':'CHARTEUSE AMARILLO',
                'subtitulo':'',
                'botella':'',
                'copa':'205'
            },
            {
                'nombre':'CHARTREUSE VERDE',
                'subtitulo':'',
                'botella':'',
                'copa':'240'
            },
            {
                'nombre':'FERNET BRANCA',
                'subtitulo':'',
                'botella':'',
                'copa':'150'
            },
            {
                'nombre':'FRANGELICO',
                'subtitulo':'',
                'botella':'',
                'copa':'180'
            },
            {
                'nombre':'GRAND MENIER',
                'subtitulo':'',
                'botella':'',
                'copa':'210'
            },
            {
                'nombre':'KAHLUA',
                'subtitulo':'',
                'botella':'',
                'copa':'90'
            },
            {
                'nombre':'LICOR 43',
                'subtitulo':'',
                'botella':'',
                'copa':'190'
            },
            {
                'nombre':'LICOR 43 OROCHATA',
                'subtitulo':'',
                'botella':'',
                'copa':'160'
            },
            {
                'nombre':'RUM CHATA',
                'subtitulo':'',
                'botella':'',
                'copa':'140'
            },
            {
                'nombre':'SAMBUCA NEGRO',
                'subtitulo':'',
                'botella':'',
                'copa':'124'
            },
            {
                'nombre':'SAMBUCA BLANCO',
                'subtitulo':'',
                'botella':'',
                'copa':'118'
            },
            {
                'nombre':'STREGA',
                'subtitulo':'',
                'botella':'',
                'copa':'180'
            },
            {
                'nombre':'PAMA (LICOR DE GRANADA)',
                'subtitulo':'',
                'botella':'',
                'copa':'170'
            },
        ]
    },
]

/* Mixología agatha */
export const mixologia1 = [
    {
        'id':'left',
        'platillos': [
            {
                'nombre':'ROUGE',
                'gramos':'120 ml',
                'des':'Vodka ketel One, limón, jarabe natural, nieve de frutos rojos y puré de fresa.',
                'precio':'180'
            },
            {
                'nombre':'PINK DIAMON',
                'gramos':'120 ml',
                'des':'Ginebra Mom, jarabe de rosas, limón, almíbar, con crusta de azúcar de rosa.',
                'precio':'270'
            },
            {
                'nombre':'MEXICAN ONE',
                'gramos':'120 ml',
                'des':'Mezcal Unión Uno, jarabe de jamaica infucionado con pimiento morrón, limón, Espuma de jicama y crusta de sal de albahaca.',
                'precio':'160'
            },
            {
                'nombre':'GOLD SMOKE',
                'gramos':'120 ml',
                'des':'Whisky Gold Label, puré de maracuyá, miel de abeja, limón, ahumado con canela.',
                'precio':'320'
            },
            {
                'nombre':'GREEN TAIL',
                'gramos':'120 ml',
                'des':'Mezcal 400 Conejos, chile serrano, limón y crusta de sal de albahaca.',
                'precio':'150'
            },
            {
                'nombre':'ROBERT´S MARGARITA',
                'gramos':'120 ml',
                'des':'Tequila Don Julio 70, licor de mango Onilikan, limón, miel de agave, bitter de Angostura, sal de gusano.',
                'precio':'250'
            },
            {
                'nombre':'MOSCOW MULE',
                'gramos':'150 ml',
                'des':'Vodka Ketel One, jengibre, limón, jarabe natural, ginger ale y soda.',
                'precio':'190'
            },
            {
                'nombre':'JAGUAR',
                'gramos':'120 ml',
                'des':'Ginebra Bombay Sapphire, licor de frambuesa Chambord, limón, jarabe natural y nube de guayaba.',
                'precio':'220'
            },
        ]
    },
    {
        'id':'right',
        'platillos': [
            {
                'nombre':'SOUTH SIDE',
                'gramos':'120 ml',
                'des':'Ginebra Tanqueray Dry, limón, jarabe natural, hierbabuena fresca. ',
                'precio':'140'
            },
            {
                'nombre':'ANANDA REYES',
                'gramos':'120 ml',
                'des':'Mezcal Unión, Licor Ancho Reyes, piña, jarabe de Agave, limón.',
                'precio':'180'
            },
            {
                'nombre':'ELIXIR',
                'gramos':'120 ml',
                'des':'Mezcal Unión, Licor de Agave Onilikan, jarabe de jamaica, limón, hierbabuena fresca y crusta de sal gusano.',
                'precio':'190'
            },
            {
                'nombre':'MEXICAN RUDE',
                'gramos':'250 ml',
                'des':'Tequila Patrón Reposado, licor Ancho Reyes, jugo de naranja, limón, jarabe de cerveza, bitter de cardamomo, crusta de sal de chiles.',
                'precio':'190'
            },
            {
                'nombre':'10 DE ENERO',
                'gramos':'142 ml',
                'des':'Tequila Patrón Reposado, maracuyá, limón, naranja y azúcar.',
                'precio':'210'
            },
            {
                'nombre':'GALLARDO',
                'gramos':'120 ml',
                'des':'Licor 43 Orochata, maracuyá, proteína natural y twist de naranja.',
                'precio':'160'
            },
            {
                'nombre':'DAI SUKI',
                'gramos':'120 ml',
                'des':'Vodka Stolichnaya, licor de ciruela Plum Dew, limón y vino espumoso.',
                'precio':'180'
            },
            {
                'nombre':'BEERY COOLER',
                'gramos':'355 ml',
                'des':'Vino Tinto, frutos rojos, limón, jarabe natural y Ginger Ale.',
                'precio':'170'
            }
        ]
    }
]
export const mixologia = [
    {
        'nombre':'COCTELES VIRGENES',
        'botella':'no',
        'copa':'no',
        'elements': [
            {
                'nombre':'MARIA',
                'subtitulo':'',
                'botella':'',
                'copa':'97'
            },
            {
                'nombre':'MURICATA',
                'subtitulo':'',
                'botella':'',
                'copa':'97'
            },
            {
                'nombre':'SANTO REMEDIO',
                'subtitulo':'',
                'botella':'',
                'copa':'97'
            },
            {
                'nombre':'JAMAICA STYLE',
                'subtitulo':'',
                'botella':'',
                'copa':'97'
            },
            {
                'nombre':'POMELO SPICY',
                'subtitulo':'',
                'botella':'',
                'copa':'97'
            },
            {
                'nombre':'COCTEL ROJO',
                'subtitulo':'',
                'botella':'',
                'copa':'97'
            },
            {
                'nombre':'GIALLO',
                'subtitulo':'',
                'botella':'',
                'copa':'97'
            },
            {
                'nombre':'WILD ROSE',
                'subtitulo':'',
                'botella':'',
                'copa':'97'
            },
            {
                'nombre':'STRAWBERRY COCKTAIL',
                'subtitulo':'',
                'botella':'',
                'copa':'97'
            },
            {
                'nombre':'LIMONJITO',
                'subtitulo':'',
                'botella':'',
                'copa':'97'
            },
            {
                'nombre':'BICHI',
                'subtitulo':'',
                'botella':'',
                'copa':'97'
            },
        ]
    },
    {
        'nombre':'SOFT DRINKS',
        'botella':'no',
        'copa':'no',
        'elements': [
            {
                'nombre':'REFRESCOS COCA COLA',
                'subtitulo':'355 ML',
                'botella':'',
                'copa':'50'
            },
            {
                'nombre':'NARANJADA',
                'subtitulo':'270 ML',
                'botella':'',
                'copa':'52'
            },
            {
                'nombre':'LIMONADA',
                'subtitulo':'270 ML',
                'botella':'',
                'copa':'52'
            },
            {
                'nombre':'PERRIER',
                'subtitulo':'330 ML',
                'botella':'',
                'copa':'70'
            },
            {
                'nombre':'A. MINERAL T. CHICO',
                'subtitulo':'340 ML',
                'botella':'',
                'copa':'45'
            },
            {
                'nombre':'AGUA ST. PELLEGRINO',
                'subtitulo':'250 ML',
                'botella':'',
                'copa':'50'
            },
            {
                'nombre':'GINGER ALE',
                'subtitulo':'355 ML',
                'botella':'',
                'copa':'60'
            },
            {
                'nombre':'AGUA CIEL',
                'subtitulo':'',
                'botella':'',
                'copa':'40'
            },
        ]
    },
    {
        'nombre':'CERVECERIA MODELO',
        'botella':'no',
        'copa':'no',
        'elements': [
            {
                'nombre':'CORONA',
                'subtitulo':'355 ML',
                'botella':'',
                'copa':'55'
            },
            {
                'nombre':'PACIFICO',
                'subtitulo':'355 ML',
                'botella':'',
                'copa':'55'
            },
            {
                'nombre':'NEGRA MODELO',
                'subtitulo':'355 ML',
                'botella':'',
                'copa':'76'
            },
            {
                'nombre':'MODELO ESPECIAL',
                'subtitulo':'355 ML',
                'botella':'',
                'copa':'76'
            },
            {
                'nombre':'BUD LIGHT',
                'subtitulo':'355 ML',
                'botella':'',
                'copa':'80'
            },
            {
                'nombre':'MICHELOB ULTRA',
                'subtitulo':'355 ML',
                'botella':'',
                'copa':'80'
            },
            {
                'nombre':'STELLA ARTROIS',
                'subtitulo':'355 ML',
                'botella':'',
                'copa':'90'
            },
            {
                'nombre':'PACIFICO SUAVE',
                'subtitulo':'355 ML',
                'botella':'',
                'copa':'58'
            },
        ]
    },
    {
        'nombre':'CERVECERIA CUAHUTEMOC',
        'botella':'no',
        'copa':'no',
        'elements': [
            {
                'nombre':'INDIO',
                'subtitulo':'325 ML',
                'botella':'',
                'copa':'55'
            },
            {
                'nombre':'TECATE LIGHT',
                'subtitulo':'325 ML',
                'botella':'',
                'copa':'55'
            },
            {
                'nombre':'XX LAGER',
                'subtitulo':'325 ML',
                'botella':'',
                'copa':'55'
            },
            {
                'nombre':'BOHEMIA CLARA',
                'subtitulo':'355 ML',
                'botella':'',
                'copa':'75'
            },
            {
                'nombre':'BOHEMIA OSCURA',
                'subtitulo':'355 ML',
                'botella':'',
                'copa':'75'
            },
            {
                'nombre':'MILLER HIGH LIFE',
                'subtitulo':'355 ML',
                'botella':'',
                'copa':'90'
            },
            {
                'nombre':'HEINEKEN',
                'subtitulo':'355 ML',
                'botella':'',
                'copa':'85'
            },
        ]
    },
    {
        'nombre':'CERVESAS ARTESANALES',
        'botella':'no',
        'copa':'no',
        'elements': [
            {
                'nombre':'TIKUS PORTER',
                'subtitulo':'355 ML',
                'botella':'',
                'copa':'105'
            },
            {
                'nombre':'COLIMITA LAGER',
                'subtitulo':'355 ML',
                'botella':'',
                'copa':'105'
            },
            {
                'nombre':'PARAMO PALE ALE',
                'subtitulo':'355 ML',
                'botella':'',
                'copa':'105'
            },
        ]
    },
]

/* Example */
export const Example = [
    { 
        'id':'left',
        'platillos': [
            {
                'nombre':'',
                'gramos':'',
                'des':'',
                'precio':''
            },
        ]
    },
    {
        'id':'right',
        'platillos': [
            {
                'nombre':'',
                'gramos':'',
                'des':'',
                'precio':''
            },
        ]
    }
]

/* Bebida example */
export const bebidaExample = [
    {
        'nombre':'',
        'botella':'',
        'copa':'',
        'elements': [
            {
                'nombre':'',
                'subtitulo':'',
                'botella':'',
                'copa':''
            },
        ]
    },
]