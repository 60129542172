import { Navbar } from "../components/Navigation"
import { ResponsiveImage, Image } from "../components/Images"
import { Footer, Copyright } from "../components/Footer"
import { Link } from "react-router-dom"
import home from "./../styles/home.module.css"
import cx from "classnames"

export default function Home() {
    return(
        <>
            <Navbar/>

            <div className={ home.header }>
                <ResponsiveImage classes={ home.header_bg } route="home/hero" alt="Platillo de Agatha" />
                <div className={ home.header_shadow }></div>
            </div>
            
            {/* Esta vida me encanta */}
            <div id="concepto" className={ cx(home.vida, 'section')}>
                <div className={ cx(home.vida_content, 'flex_content', 'content')}>
                    <div className={ cx(home.vida_img, 'content_half') }>
                        <img src="/images/home/vida.png" alt="Cocinero" />
                    </div>
                    <div className={ cx(home.vida_des, 'content_half') }>
                        <div className={ home.vida_des__sep }></div>
                        <div className={ cx(home.vida_des__t1, home.title) }>ESTA VIDA ME ENCANTA</div>
                        <div className={ cx(home.vida_des__t2, home.des) }>Agatha inspira armonía y equilibrio. Un concepto único en su género, donde cada platillo y cada bebida, son preparados cuidadosamente para darte una experiencia gourmet sin igual.</div>
                        <div className={ cx(home.vida_des__t2, home.des) }>Conoce nuestra variada gastronomía y mixología que te transmitirán un ambiente mágico y confortable. Tu lugar ideal es aquí.</div>
                        <a href="tel:6699903202" className={ cx(home.vida_des__btn, home.button) }>Reservar<span></span></a>
                    </div>
                </div>
            </div>

            {/* Kitchen show */}
            <div className={ cx(home.menu, 'section') }>
                <div className={ cx(home.menu_content, 'content') }>
                    <div className={ home.menu__t1 }>Sabor y Magia</div>
                    <div className={ cx(home.menu__t2, home.title) }>Kitchen Show</div>
                    <div className={ home.menu__t3 }>Todo el sabor y magia en cada bocado. Conoce el menú que nuestros chefs han preparado minuciosamente con ingredientes de calidad para ofrecerte una experiencia inigualable en todo momento.</div>
                    <div className={ home.menu_con }>
                        <picture>
                            <source type="image/webp" srcSet="/images/home/menu@1500.webp" media="(min-width:1200px)"/>
                            <source type="image/png" srcSet="/images/home/menu@1500.png" media="(min-width:1200px)"/>
                            <source type="image/webp" srcSet="/images/home/menu@1200.webp" media="(max-width:1199px) and (min-width: 900px)"/>
                            <source type="image/png" srcSet="/images/home/menu@1200.png" media="(max-width:1199px) and (min-width: 900px)"/>
                            <source type="image/webp" srcSet="/images/home/menu@900.webp" media="(max-width:899px) and (min-width: 600px)"/>
                            <source type="image/png" srcSet="/images/home/menu@900.png" media="(max-width:899px) and (min-width: 600px)"/>
                            <img src="/images/home/menu@600.png" srcSet="/images/home/menu@600.webp" alt="Platillo" className={ home.menu_con__bg }/>
                        </picture>
                        <Link to="/menu" className={ cx(home.menu_con__link, home.button) }>Ver menú<span></span></Link>
                    </div>
                </div>
            </div>

            {/* Cocktail show */}
            <div className={ cx(home.cocktail, 'section') }>
                <div className={ cx(home.cocktail_content, 'flex_content', 'content') }>
                    <div className={ cx(home.cocktail_des, 'content_half') }>
                        <div className={ cx(home.cocktail_des__t1, home.title) }>Cocktail Show</div>
                        <div className={ cx(home.cocktail_des__t2, home.des) }>Nuestra auténtica mixología te encantará. Creaciones y mezclas únicas donde le damos un toque especial para ti, usando técnicas excepcionales que le darán vida a tus bebidas. Conoce las sensaciones que harán que tu experiencia en Agatha esté llena de magia.</div>
                    </div>
                    <div className={ cx(home.cocktail_img, 'content_half') }>
                        <Image route="/images/home/cocktail" alt="Bebida"/>
                    </div>
                </div>
            </div>

            {/* Redes sociales */}
            <div className={ cx(home.redes, 'section') }>
                <div className={ cx(home.redes_content, 'content') }>
                    <div className={ home.redes__t1 }>Síguenos en instagram</div>
                    <div className={ home.redes_con }>
                        <Image route="/images/footer/inst1" alt="Imagen de instagram"/>
                        <Image route="/images/footer/inst2" alt="Imagen de instagram"/>
                        <Image route="/images/footer/inst3" alt="Imagen de instagram"/>
                        <a href="https://www.instagram.com/agatha.kb/" target="_blank" className={ home.redes_con__link }></a>
                    </div>
                </div>
            </div>

            <Footer/>
            <Copyright/>
        </>
    )
}